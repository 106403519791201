import React, { FC } from "react"
import {
  AutocompleteInput,
  BooleanField,
  ChipField,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  List,
  NumberField,
  NumberInput,
  RadioButtonGroupInput,
  ReferenceArrayField,
  ReferenceField,
  ReferenceInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin"

import { PurchaseStatus } from "./generated/graphql"
import { EditToolbar, SearchFilter } from "./utils"

const purchaseStatusChoices = [
  ...Object.keys(PurchaseStatus).map((k) => ({
    id: k,
    name: k,
  })),
]

export const PurchaseCreate: FC<any> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput reference="User" source="owner">
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <ReferenceInput reference="Product" source="product">
        <AutocompleteInput optionText="code" />
      </ReferenceInput>
      <TextInput source="message" />
      <TextInput source="payee" />
      <RadioButtonGroupInput choices={purchaseStatusChoices} source="status" />
    </SimpleForm>
  </Create>
)

export const PurchaseEdit: FC<any> = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<EditToolbar />}>
        <TextField source="id" />
        <ReferenceInput reference="User" source="owner">
          <AutocompleteInput optionText="username" />
        </ReferenceInput>
        <ReferenceField reference="User" source="purchaserId">
          <ChipField source="username" />
        </ReferenceField>
        <ReferenceArrayField reference="PurchaseTransfer" source="transferHistory">
          <Datagrid>
            <ReferenceField reference="User" source="sender">
              <ChipField source="username" />
            </ReferenceField>
            <ReferenceField reference="User" source="recipient">
              <ChipField source="username" />
            </ReferenceField>
            <DateField showTime source="createdAt" />
          </Datagrid>
        </ReferenceArrayField>

        <TextInput source="message" />
        <NumberField source="price" />
        <NumberInput source="fee" />
        <NumberInput source="discount" />
        <NumberInput source="amount" />
        <NumberInput source="refundedAmount" />
        <TextInput source="payee" />
        <TextField source="paymentMethod" />
        <RadioButtonGroupInput choices={purchaseStatusChoices} source="status" />
        <TextInput source="statusMessage" />
        <BooleanField source="fromWeb" />
        <BooleanField source="sendQR" />
        <DateTimeInput allowEmpty showTime={true} source="usedAt" />
        <ReferenceField reference="ClaimedBadge" source="badge">
          <ChipField source="id" />
        </ReferenceField>
        <ReferenceField reference="Product" source="product">
          <ChipField source="code" />
        </ReferenceField>
        <ReferenceField reference="Promocode" source="promocode">
          <ChipField source="name" />
        </ReferenceField>
        <DateField showTime={true} source="createdAt" />
      </SimpleForm>
    </Edit>
  )
}

export const PurchaseList: FC<any> = (props) => (
  <List
    {...props}
    filters={
      <SearchFilter {...props}>
        <TextInput label="PurchaseId" source="id" />
        <TextInput label="Status" source="status" />
        <TextInput label="Product" source="product.name" />
        <TextInput label="ProductId" source="product.id" />
        <TextInput label="Owner" source="owner.username" />
        <TextInput label="Payee" source="payee" />
      </SearchFilter>
    }
  >
    <Datagrid rowClick="edit">
      <ReferenceField reference="User" source="owner">
        <ChipField source="username" />
      </ReferenceField>
      <ReferenceField reference="Product" source="product">
        <ChipField source="code" />
      </ReferenceField>
      <TextField source="message" />
      <TextField source="payee" />
      <TextField source="status" />
      <ReferenceField reference="ClaimedBadge" source="badge">
        <ChipField source="id" />
      </ReferenceField>
      <BooleanField source="fromWeb" />
      <BooleanField source="sendQR" />
      <BooleanField source="hasBeenUsed" />
      <DateField showTime={true} source="createdAt" />
    </Datagrid>
  </List>
)
