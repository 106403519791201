import MarkdownInput from "ra-input-markdown"
import React, { FC } from "react"
import {
  AutocompleteInput,
  BooleanInput,
  ChipField,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  List,
  NumberField,
  NumberInput,
  ReferenceField,
  ReferenceInput,
  SelectField,
  SelectInput,
  SimpleForm,
  TextField,
  TextInput,
} from "react-admin"
import { JsonInput } from "react-admin-json-view"

import MediaWithUpload from "./components/MediaWithUpload"
import { ProductStatus, ProductType } from "./generated/graphql"
import { EditToolbar, SearchFilter } from "./utils"

const productStatusChoices = [
  ...Object.keys(ProductStatus).map((k) => ({
    id: k,
    name: k,
  })),
]

const productTypeChoices = [
  ...Object.keys(ProductType).map((k) => ({
    id: k,
    name: k,
  })),
]

export const ProductCreate: FC<any> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <SelectInput choices={productTypeChoices} source="productType" />
      <TextInput source="description" />
      <NumberInput source="order" />
      <MarkdownInput source="additionalInfo" />
      <TextInput source="code" />
      <TextInput multiline source="payees" />
      <NumberInput source="price" />
      <NumberInput label="VAT (percentage)" source="vat" />
      <NumberInput source="quantity" />
      <NumberInput label="Limit per user" source="limit" />
      <ReferenceInput reference="Community" source="community">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <BooleanInput source="showBeforeStartDate" />
      <DateTimeInput source="startDate" />
      <DateTimeInput source="endDate" />
      <JsonInput
        jsonString={false}
        reactJsonOptions={{
          collapsed: true,
          displayDataTypes: false,
          enableClipboard: false,
          name: null,
        }}
        source="styleOverride"
      />
    </SimpleForm>
  </Create>
)

export const ProductEdit: FC<any> = (props) => {
  return (
    <Edit {...props}>
      <SimpleForm toolbar={<EditToolbar />}>
        <TextField source="id" />
        <TextInput source="name" />
        <SelectInput choices={productTypeChoices} source="productType" />
        <NumberInput source="order" />
        <SelectInput choices={productStatusChoices} source="publishingStatus" />
        <BooleanInput source="isDisabled" />
        <TextInput source="description" />
        <MarkdownInput source="additionalInfo" />
        <TextInput source="code" />
        <TextInput source="pickupInfo" />
        <TextInput multiline source="payees" />
        <NumberInput source="price" />
        <NumberInput label="VAT (percentage)" source="vat" />
        <NumberInput source="quantity" />
        <NumberInput label="Limit per user" source="limit" />
        <NumberField source="totalSold" />
        <TextInput source="mediaUrl" />
        <MediaWithUpload
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          filename={props["id"] as string}
          label="Image"
          path="product/image"
        />
        <ReferenceInput reference="Community" source="community">
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput reference="Event" source="event">
          <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <BooleanInput source="showBeforeStartDate" />
        <DateTimeInput source="startDate" />
        <DateTimeInput source="endDate" />
        <DateTimeInput source="soldoutAfter" />
        <JsonInput
          jsonString={false}
          reactJsonOptions={{
            collapsed: true,
            displayDataTypes: false,
            enableClipboard: false,
            name: null,
          }}
          source="fees"
        />
        <TextInput allowNull source="customReceiptAlias" />
      </SimpleForm>
    </Edit>
  )
}

export const ProductList: FC<any> = (props) => (
  <List
    {...props}
    filter={{
      publishingStatus: {
        in: ["Draft", "CompleteAvailable", "CompleteUnavailable"],
      },
    }}
    filters={SearchFilter(props)}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <SelectField choices={productTypeChoices} source="productType" />
      <TextField source="publishingStatus" />
      <TextField source="description" />
      <TextField source="code" />
      <TextField source="payees" />
      <NumberField source="price" />
      <NumberField source="quantity" />
      <NumberField source="totalSold" />
      <TextField source="status" />
      <ReferenceField reference="Community" source="community">
        <ChipField source="name" />
      </ReferenceField>
      <DateField source="startDate" />
      <DateField source="endDate" />
    </Datagrid>
  </List>
)
