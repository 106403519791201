import TrackIcon from "@material-ui/icons/Audiotrack"
import BadgeIcon from "@material-ui/icons/Cake"
import TalksMessageIcon from "@material-ui/icons/Chat"
import InviteCodeIcon from "@material-ui/icons/ConfirmationNumber"
import DynamicFeedIcon from "@material-ui/icons/DynamicFeed"
import EventIcon from "@material-ui/icons/Event"
import CommunityIcon from "@material-ui/icons/Group"
import PromocodeIcon from "@material-ui/icons/LocalOffer"
import HighlightsIcon from "@material-ui/icons/NotificationImportant"
import CatalogIcon from "@material-ui/icons/Panorama"
import UserIcon from "@material-ui/icons/Person"
import FaqIcon from "@material-ui/icons/QuestionAnswer"
import Send from "@material-ui/icons/Send"
import ShoppingCartIcon from "@material-ui/icons/ShoppingCart"
import StoreIcon from "@material-ui/icons/Store"
import MassMessageIcon from "@material-ui/icons/Telegram"
import { useDataProvider } from "@ra-data-prisma/dataprovider"
import { FC } from "react"
import { Admin, AuthProvider, Resource } from "react-admin"

import { ActivityEventsList } from "./activityEvent"
import {
  ClaimedBadgeCreate,
  ClaimedBadgeEdit,
  ClaimedBadgeList,
  ClaimedBadgeShow,
} from "./badge-claimed"
import {
  TemplateBadgeCreate,
  TemplateBadgeEdit,
  TemplateBadgeList,
  TemplateBadgeShow,
} from "./badge-template"
import {
  CatalogCategoryCreate,
  CatalogCategoryEdit,
  CatalogCategoryList,
  CatalogCategoryShow,
} from "./catalogCategory"
import {
  CatalogItemScheduleCreate,
  CatalogItemScheduleEdit,
  CatalogItemScheduleList,
  CatalogItemScheduleShow,
} from "./catalogEvents"
import { CatalogItemCreate, CatalogItemEdit, CatalogItemList, CatalogItemShow } from "./catalogItem"
import { CommunityCreate, CommunityEdit, CommunityList, CommunityShow } from "./community"
import { CommunityMemberInfoList, CommunityMemberInfoShow } from "./communityMemberInfo"
import { EventCreate, EventEdit, EventList, EventShow } from "./event"
import {
  EventCategoryCreate,
  EventCategoryEdit,
  EventCategoryList,
  EventCategoryShow,
} from "./eventCategory"
import { FaqCategoryCreate, FaqCategoryEdit, FaqCategoryList, FaqCategoryShow } from "./faqCategory"
import { FaqItemCreate, FaqItemEdit, FaqItemList, FaqItemShow } from "./faqItem"
import {
  FeedCategoryCreate,
  FeedCategoryEdit,
  FeedCategoryList,
  FeedCategoryShow,
} from "./feedCategory"
import { FeedItemCreate, FeedItemEdit, FeedItemList } from "./feedItem"
import { FeedItemCommentCreate, FeedItemCommentEdit, FeedItemCommentList } from "./feedItemComment"
import {
  FeedItemCommentReportCreate,
  FeedItemCommentReportEdit,
  FeedItemCommentReportList,
} from "./feedItemCommentReport"
import { ForumCommentEdit, ForumCommentList } from "./forumComment"
import { ForumContentReportEdit, ForumContentReportList } from "./forumContentReport"
import { ForumPostCreate, ForumPostEdit, ForumPostList } from "./forumPost"
import { ForumTagCreate, ForumTagEdit, ForumTagList } from "./forumTag"
import { HighlightCreate, HighlightEdit, HighlightList } from "./highlight"
import { InviteCodeCreate, InviteCodeEdit, InviteCodeList, InviteCodeShow } from "./inviteCode"
import { MassMessageCreate, MassMessageEdit, MassMessageList } from "./mass-message"
import { ProductCreate, ProductEdit, ProductList } from "./product"
import { PromocodeCreate, PromocodeEdit, PromocodeList, PromocodeShow } from "./promocode"
import { PurchaseCreate, PurchaseEdit, PurchaseList } from "./purchase"
import { PurchaseTransferList } from "./purchaseTransfer"
import {
  TalksMessageCreate,
  TalksMessageEdit,
  TalksMessageList,
  TalksMessageShow,
} from "./talks-message"
import { TrackCreate, TrackEdit, TrackList } from "./track"
import { UserCreate, UserEdit, UserList, UserShow } from "./user"
import { login } from "./utils/api"
import {
  WaitlistUserCreate,
  WaitlistUserEdit,
  WaitlistUserList,
  WaitlistUserShow,
} from "./waitlist-user"

const App: FC = () => {
  const authProvider: AuthProvider = {
    checkAuth: () => {
      return localStorage.getItem("token") ? Promise.resolve() : Promise.reject()
    },
    checkError: () => {
      return Promise.resolve()
    },
    getPermissions: () => {
      return Promise.resolve()
    },
    login: async ({ username, password }: { username: string; password: string }) => {
      const data = await login(username, password)
      if (data?.login?.token) {
        localStorage.setItem("token", data.login.token)
      } else {
        throw new Error("Invalid admin credentials.")
      }
      return Promise.resolve()
    },
    logout: () => {
      if (localStorage.getItem("token")) {
        localStorage.removeItem("token")
      }
      return Promise.resolve()
    },
  }

  const dataProvider = useDataProvider({
    aliasPrefix: "admin",
    clientOptions: {
      // @ra-data-prisma/dataprovider does not support headers so we send token as a query string.
      uri:
        (process.env.REACT_APP_API_URL ?? "/") +
        `?client=werz-admin&token=${localStorage.getItem("token") ?? ""}`,
    },
  })

  if (!dataProvider) {
    return <div />
  }

  return (
    <Admin authProvider={authProvider} dataProvider={dataProvider}>
      <Resource
        create={UserCreate}
        edit={UserEdit}
        icon={UserIcon}
        list={UserList}
        name="User"
        show={UserShow}
      />
      <Resource
        create={WaitlistUserCreate}
        edit={WaitlistUserEdit}
        icon={UserIcon}
        list={WaitlistUserList}
        name="WaitlistUser"
        options={{
          label: "Waitlist Emails",
        }}
        show={WaitlistUserShow}
      />
      <Resource
        create={CommunityCreate}
        edit={CommunityEdit}
        icon={CommunityIcon}
        list={CommunityList}
        name="Community"
        show={CommunityShow}
      />
      <Resource
        icon={CommunityIcon}
        list={CommunityMemberInfoList}
        name="CommunityMemberInfo"
        options={{
          label: "Community Member Info",
        }}
        show={CommunityMemberInfoShow}
      />
      <Resource
        create={EventCreate}
        edit={EventEdit}
        icon={EventIcon}
        list={EventList}
        name="Event"
        show={EventShow}
      />
      <Resource
        create={EventCategoryCreate}
        edit={EventCategoryEdit}
        icon={EventIcon}
        list={EventCategoryList}
        name="EventCategory"
        options={{
          label: "Event Categories",
        }}
        show={EventCategoryShow}
      />
      <Resource
        create={CatalogItemCreate}
        edit={CatalogItemEdit}
        icon={CatalogIcon}
        list={CatalogItemList}
        name="CatalogItem"
        options={{
          label: "Catalog Items",
        }}
        show={CatalogItemShow}
      />
      <Resource
        create={CatalogCategoryCreate}
        edit={CatalogCategoryEdit}
        icon={CatalogIcon}
        list={CatalogCategoryList}
        name="CatalogItemCategory"
        options={{
          label: "Catalog Categories",
        }}
        show={CatalogCategoryShow}
      />
      <Resource
        create={CatalogItemScheduleCreate}
        edit={CatalogItemScheduleEdit}
        icon={CatalogIcon}
        list={CatalogItemScheduleList}
        name="CatalogItemSchedule"
        options={{
          label: "Schedule Items",
        }}
        show={CatalogItemScheduleShow}
      />
      <Resource
        create={FaqItemCreate}
        edit={FaqItemEdit}
        icon={FaqIcon}
        list={FaqItemList}
        name="FaqItem"
        options={{
          label: "FAQ Items",
        }}
        show={FaqItemShow}
      />
      <Resource
        create={FaqCategoryCreate}
        edit={FaqCategoryEdit}
        icon={FaqIcon}
        list={FaqCategoryList}
        name="FaqCategory"
        options={{
          label: "FAQ Categories",
        }}
        show={FaqCategoryShow}
      />
      <Resource
        create={InviteCodeCreate}
        edit={InviteCodeEdit}
        icon={InviteCodeIcon}
        list={InviteCodeList}
        name="InviteCode"
        options={{
          label: "Invite Codes",
        }}
        show={InviteCodeShow}
      />
      <Resource
        create={MassMessageCreate}
        edit={MassMessageEdit}
        icon={MassMessageIcon}
        list={MassMessageList}
        name="MassMessage"
        options={{
          label: "Mass Messages",
        }}
      />
      <Resource
        create={TalksMessageCreate}
        edit={TalksMessageEdit}
        icon={TalksMessageIcon}
        list={TalksMessageList}
        name="TalksMessage"
        options={{
          label: "Talks Messages",
        }}
        show={TalksMessageShow}
      />
      <Resource
        create={HighlightCreate}
        edit={HighlightEdit}
        icon={HighlightsIcon}
        list={HighlightList}
        name="Highlight"
        options={{
          label: "Highlights",
        }}
      />
      <Resource
        create={ProductCreate}
        edit={ProductEdit}
        icon={StoreIcon}
        list={ProductList}
        name="Product"
        options={{
          label: "Products",
        }}
      />
      <Resource
        create={PromocodeCreate}
        edit={PromocodeEdit}
        icon={PromocodeIcon}
        list={PromocodeList}
        name="Promocode"
        options={{
          label: "Promocodes",
        }}
        show={PromocodeShow}
      />
      <Resource
        create={PurchaseCreate}
        edit={PurchaseEdit}
        icon={ShoppingCartIcon}
        list={PurchaseList}
        name="Purchase"
        options={{
          label: "Purchases",
        }}
      />
      <Resource
        icon={Send}
        list={PurchaseTransferList}
        name="PurchaseTransfer"
        options={{
          label: "Purchase Transfers",
        }}
      />
      <Resource
        create={FeedItemCreate}
        edit={FeedItemEdit}
        icon={DynamicFeedIcon}
        list={FeedItemList}
        name="FeedItem"
        options={{
          label: "Announcements",
        }}
      />
      <Resource
        create={FeedItemCommentCreate}
        edit={FeedItemCommentEdit}
        icon={DynamicFeedIcon}
        list={FeedItemCommentList}
        name="FeedItemComment"
        options={{
          label: "Comments",
        }}
      />
      <Resource
        create={FeedItemCommentReportCreate}
        edit={FeedItemCommentReportEdit}
        icon={DynamicFeedIcon}
        list={FeedItemCommentReportList}
        name="FeedItemCommentReport"
        options={{
          label: "Reports (comment)",
        }}
      />
      <Resource
        create={FeedCategoryCreate}
        edit={FeedCategoryEdit}
        icon={DynamicFeedIcon}
        list={FeedCategoryList}
        name="FeedCategory"
        options={{
          label: "Announcement Categories",
        }}
        show={FeedCategoryShow}
      />
      <Resource
        create={TrackCreate}
        edit={TrackEdit}
        icon={TrackIcon}
        list={TrackList}
        name="Track"
        options={{
          label: "Radio Tracks",
        }}
      />
      <Resource
        create={TemplateBadgeCreate}
        edit={TemplateBadgeEdit}
        icon={BadgeIcon}
        list={TemplateBadgeList}
        name="TemplateBadge"
        options={{
          label: "Template Badges",
        }}
        show={TemplateBadgeShow}
      />
      <Resource
        create={ClaimedBadgeCreate}
        edit={ClaimedBadgeEdit}
        icon={BadgeIcon}
        list={ClaimedBadgeList}
        name="ClaimedBadge"
        options={{
          label: "Claimed Badges",
        }}
        show={ClaimedBadgeShow}
      />
      <Resource
        icon={Send}
        list={ActivityEventsList}
        name="ActivityEvent"
        options={{
          label: "Activity Events",
        }}
      />
      <Resource
        create={ForumTagCreate}
        edit={ForumTagEdit}
        list={ForumTagList}
        name="ForumTag"
        options={{
          label: "Forum Tags",
        }}
      />
      <Resource
        create={ForumPostCreate}
        edit={ForumPostEdit}
        list={ForumPostList}
        name="ForumPost"
        options={{
          label: "Forum Posts",
        }}
      />
      <Resource
        edit={ForumCommentEdit}
        list={ForumCommentList}
        name="ForumComment"
        options={{
          label: "Forum Comments",
        }}
      />
      <Resource
        edit={ForumContentReportEdit}
        list={ForumContentReportList}
        name="ForumContentReport"
        options={{
          label: "Forum Reports",
        }}
      />
    </Admin>
  )
}

export default App
