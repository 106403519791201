import { Button } from "@material-ui/core"
import { gql } from "graphql-request"
import MarkdownInput from "ra-input-markdown"
import React, { FC, useState } from "react"
import {
  AutocompleteArrayInput,
  AutocompleteInput,
  BooleanField,
  BooleanInput,
  ChipField,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  List,
  NumberField,
  NumberInput,
  ReferenceArrayInput,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  Show,
  showNotification,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
  useRefresh,
} from "react-admin"
import { JsonInput } from "react-admin-json-view"

import MediaWithUpload from "./components/MediaWithUpload"
import { EventStatus } from "./generated/graphql"
import { EditToolbar, SearchFilter } from "./utils"
import { client } from "./utils/api"

const eventStatusChoices = [
  ...Object.keys(EventStatus).map((k) => ({
    id: k,
    name: k,
  })),
]

export const EventCreate: FC<any> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <BooleanInput source="isPrivate" />
      <BooleanInput source="isLocked" />
      <BooleanInput source="isMaster" />
      <ReferenceArrayInput reference="EventCategory" source="categories">
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput reference="FeedItem" source="feed">
        <AutocompleteArrayInput optionText="title" />
      </ReferenceArrayInput>
      <TextInput source="type" />
      <DateTimeInput source="start" />
      <DateTimeInput source="end" />
      <DateTimeInput source="locationDropTime" />
      <MarkdownInput source="locationDescription" />
      <ReferenceInput reference="Community" source="parentCommunity">
        <AutocompleteInput source="name" />
      </ReferenceInput>
      <ReferenceArrayInput reference="Community" source="relatedCommunities">
        <AutocompleteArrayInput source="name" />
      </ReferenceArrayInput>
      <MarkdownInput source="additionalDetails" />
      <TextInput source="buyTicketsLink" />
      <TextInput label="Register / Read more link" source="registerLink" />
      <TextInput source="prePurchaseCode" />
      <BooleanInput source="allowFreeGuestList" />
      <BooleanInput source="shouldInviteCommunityMembersAutomatically" />
      <ReferenceInput reference="User" source="creator">
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <NumberInput source="latitude" />
      <NumberInput source="longitude" />
      <TextInput source="location" />
      <JsonInput
        jsonString={false}
        reactJsonOptions={{
          collapsed: true,
          displayDataTypes: false,
          enableClipboard: false,
          name: null,
        }}
        source="styleOverride"
      />
    </SimpleForm>
  </Create>
)

export const EventEdit: FC<any> = (props) => {
  const [invitingAllMembers, setInvitingAllMembers] = useState(false)
  const refresh = useRefresh()
  const mutation = gql`
    mutation AdminInviteMembers($eventId: String!) {
      adminInviteMembers(eventId: $eventId)
    }
  `
  const onInviteAllMembers = () => {
    setInvitingAllMembers(true)
    void client
      // grab signed S3 upload URL
      .request(mutation, {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        eventId: props["id"] as string,
      })
      .then(() => refresh())
      .catch(() => showNotification("Error. Please try again"))
      .finally(() => setInvitingAllMembers(false))
  }

  return (
    <Edit {...props}>
      <SimpleForm toolbar={<EditToolbar />}>
        <TextField source="id" />
        <TextInput source="name" />
        <ReferenceArrayInput reference="EventCategory" source="categories">
          <AutocompleteArrayInput optionText="name" />
        </ReferenceArrayInput>
        <SelectInput choices={eventStatusChoices} source="publishingStatus" />
        <BooleanInput source="isPrivate" />
        <BooleanInput source="isLocked" />
        <BooleanInput source="isMaster" />
        <TextInput source="type" />
        <DateTimeInput source="start" />
        <DateTimeInput source="end" />
        <DateTimeInput source="locationDropTime" />
        <MarkdownInput source="locationDescription" />
        <ReferenceInput reference="Community" source="parentCommunity">
          <AutocompleteInput source="name" />
        </ReferenceInput>
        <ReferenceArrayInput reference="Community" source="relatedCommunities">
          <AutocompleteArrayInput source="name" />
        </ReferenceArrayInput>
        <MarkdownInput source="additionalDetails" />
        <MediaWithUpload
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          filename={props["id"] as string}
          label="Additional Details Image"
          path="event/additionalDetails"
          source="mediaUrl"
        />
        <TextInput source="buyTicketsLink" />
        <TextInput label="Register / Read more link" source="registerLink" />
        <TextInput source="prePurchaseCode" />
        <ReferenceInput reference="User" source="creator">
          <AutocompleteInput optionText="username" />
        </ReferenceInput>
        <TextInput source="host" />
        <BooleanInput source="shouldInviteCommunityMembersAutomatically" />
        <Button
          color="primary"
          disabled={invitingAllMembers}
          onClick={() => {
            onInviteAllMembers()
          }}
          style={{ marginBottom: 16 }}
          variant="contained"
        >
          Invite all community members
        </Button>
        <TextField label="Total invited" source="invitedUsers.length" />
        <BooleanInput source="registrationRequired" />
        <TextField label="Total registered" source="registeredUsers.length" />
        <TextField label="Total ticketed" source="ticketedUsers.length" />
        <TextField label="Total checked in" source="checkedInUsers.length" />

        <BooleanInput source="allowFreeGuestList" />
        <ReferenceField reference="Product" source="freeGuestListProductId">
          <ChipField source="code" />
        </ReferenceField>
        <ReferenceArrayInput reference="Product" source="products">
          <AutocompleteArrayInput optionText="code" />
        </ReferenceArrayInput>

        <br />
        <NumberField source="ticketsSold" />
        <NumberField source="ticketsTotal" />
        <NumberField source="grossRevenue" />
        <NumberInput source="latitude" />
        <NumberInput source="longitude" />
        <TextInput source="location" />
        <JsonInput
          jsonString={false}
          reactJsonOptions={{
            collapsed: true,
            displayDataTypes: false,
            enableClipboard: false,
            name: null,
          }}
          source="styleOverride"
        />
      </SimpleForm>
    </Edit>
  )
}

export const EventList: FC<any> = (props) => (
  <List
    {...props}
    filter={{
      publishingStatus: {
        in: ["Draft", "Active", "CompletedDraft"],
      },
    }}
    filters={SearchFilter(props)}
  >
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <BooleanField source="isPrivate" />
      <ReferenceField reference="Community" source="parentCommunity">
        <ChipField source="name" />
      </ReferenceField>
      <TextField source="publishingStatus" />
      <DateField source="start" />
      <DateField source="end" />
      <NumberField source="ticketsSold" />
      <NumberField source="ticketsTotal" />
      <NumberField source="grossRevenue" />
      <TextField source="location" />
      <DateField source="locationDropTime" />
      <BooleanField source="shouldInviteCommunityMembersAutomatically" />
    </Datagrid>
  </List>
)

export const EventShow: FC = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <BooleanField source="isPrivate" />
      <TextField source="type" />
      <ReferenceField reference="Community" source="parentCommunity">
        <ChipField source="name" />
      </ReferenceField>
      <TextField source="additionalDetails" />
      <TextField source="buyTicketsLink" />
      <TextField label="Register / Read more link" source="registerLink" />
      <TextField source="prePurchaseCode" />
      <ReferenceField reference="User" source="creatorId">
        <ChipField source="username" />
      </ReferenceField>
      <NumberField source="latitude" />
      <NumberField source="longitude" />
      <TextField source="location" />
      <DateField source="start" />
      <DateField source="end" />
      <BooleanField source="shouldInviteCommunityMembersAutomatically" />
    </SimpleShowLayout>
  </Show>
)
