import React, { FC } from "react"
import {
  AutocompleteArrayInput,
  Create,
  Datagrid,
  Edit,
  List,
  ReferenceArrayInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin"
import { JsonField, JsonInput } from "react-admin-json-view"

import { SearchFilter } from "./utils"

export const FeedCategoryCreate: FC<any> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="code" />
      <ReferenceArrayInput reference="FeedItem" source="items">
        <AutocompleteArrayInput optionText="title" />
      </ReferenceArrayInput>
      <JsonInput
        jsonString={false}
        reactJsonOptions={{
          collapsed: true,
          displayDataTypes: false,
          enableClipboard: false,
          name: null,
        }}
        source="styleOverride"
      />
    </SimpleForm>
  </Create>
)

export const FeedCategoryEdit: FC<any> = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="code" />
      <ReferenceArrayInput reference="FeedItem" source="items">
        <AutocompleteArrayInput optionText="title" />
      </ReferenceArrayInput>
      <JsonInput
        jsonString={false}
        reactJsonOptions={{
          collapsed: true,
          displayDataTypes: false,
          enableClipboard: false,
          name: null,
        }}
        source="styleOverride"
      />
    </SimpleForm>
  </Edit>
)

export const FeedCategoryList: FC<any> = (props) => (
  <List {...props} filters={SearchFilter(props)}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
    </Datagrid>
  </List>
)

export const FeedCategoryShow: FC = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextInput source="name" />
      <JsonField
        jsonString={false}
        reactJsonOptions={{
          collapsed: true,
          displayDataTypes: false,
          enableClipboard: false,
          name: null,
        }}
        source="styleOverride"
      />
    </SimpleShowLayout>
  </Show>
)
