import React, { FC } from "react"
import {
  AutocompleteArrayInput,
  BooleanField,
  BooleanInput,
  ChipField,
  Create,
  Datagrid,
  DateField,
  DateTimeInput,
  Edit,
  ReferenceArrayField,
  ReferenceArrayInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  SingleFieldList,
  TextField,
  TextInput,
} from "react-admin"

import { BulkList } from "./components/BulkList"
import MediaWithUpload from "./components/MediaWithUpload"
import { SearchFilter } from "./utils"

export const UserCreate: FC<any> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="username" />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextInput source="email" />
      <BooleanInput source="emailVerified" />
      <TextInput source="phone" />
      <BooleanInput source="phoneVerified" />
      <DateTimeInput source="birthday" />
    </SimpleForm>
  </Create>
)

export const UserEdit: FC<any> = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextField source="id" />
      <TextInput source="username" />
      <TextInput source="firstName" />
      <TextInput source="lastName" />
      <TextField source="fullName" />
      <MediaWithUpload
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        filename={props["id"] as string}
        label="avatar"
        path="user/avatar"
        source="avatar"
      />
      <TextInput source="email" />
      <BooleanInput source="emailVerified" />
      <TextInput source="postalCode" />
      <TextInput source="phone" />
      <BooleanInput source="phoneVerified" />
      <DateTimeInput source="birthday" />
      <DateTimeInput source="createdAt" />
      <BooleanField source="fromWeb" />
      <TextField source="versionOfWerz" />
      <ReferenceArrayInput
        label="Communities"
        reference="Community"
        source="communitiesWhereMember"
      >
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput label="Invited Events" reference="Event" source="invitedEvents">
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput label="Registered Events" reference="Event" source="registeredEvents">
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput label="Ticketed Events" reference="Event" source="ticketsEvents">
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayInput label="CheckedIn Events" reference="Event" source="checkedInEvents">
        <AutocompleteArrayInput optionText="name" />
      </ReferenceArrayInput>
      <ReferenceArrayField
        filter={{ status: "Paid" }}
        label="Purchases"
        reference="Purchase"
        source="purchases"
      >
        <SingleFieldList>
          <ChipField source="message" />
        </SingleFieldList>
      </ReferenceArrayField>
      <ReferenceArrayField label="Badges" reference="ClaimedBadge" source="badges">
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <TextInput source="solanaWalletAddress" />
    </SimpleForm>
  </Edit>
)

export const UserList: FC<any> = (props) => (
  <BulkList {...props} filters={SearchFilter(props)}>
    <Datagrid rowClick="edit">
      <TextField source="username" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="email" />
      <BooleanField source="emailVerified" />
      <TextField source="phone" />
      <BooleanField source="phoneVerified" />
      <BooleanField source="fromWeb" />
      <DateField source="createdAt" />
    </Datagrid>
  </BulkList>
)

export const UserShow: FC = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="username" />
      <TextField source="firstName" />
      <TextField source="lastName" />
      <TextField source="email" />
      <TextField source="postalCode" />
      <TextField source="phone" />
      <BooleanField source="fromWeb" />
      <DateField source="createdAt" />
      <ReferenceArrayField
        label="Communities"
        reference="Community"
        source="communitiesWhereMember"
      >
        <Datagrid rowClick={"edit"}>
          <ChipField source="name" />
          <TextField source="memberSince" />
        </Datagrid>
      </ReferenceArrayField>
      <TextField label="Version of Werz" source="versionOfWerz" />
      <BooleanField source="notificationsEnabled" />
      <TextField source="pushPlayerIds" />
    </SimpleShowLayout>
  </Show>
)
