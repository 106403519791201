import React, { FC } from "react"
import {
  AutocompleteInput,
  Create,
  Datagrid,
  Edit,
  List,
  RadioButtonGroupInput,
  ReferenceField,
  ReferenceInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  TextField,
  TextInput,
} from "react-admin"

import { EditToolbar, SearchFilter } from "./utils"

export const TalksMessageCreate: FC<any> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <ReferenceInput label="Sender" reference="User" source="sender">
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <ReferenceInput label="Recipient" reference="User" source="recipient">
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <RadioButtonGroupInput
        choices={[
          { id: "Direct", name: "Direct" },
          { id: "InvitedPrivateEvent", name: "Invited To Private Event" },
          { id: "LocationDropped", name: "Location Dropped" },
          { id: "JoinedWithInviteCode", name: "Someone joined with your invite code" },
        ]}
        source="type"
      />
      <TextInput source="senderTitle" />
      <TextInput source="message" />
      <ReferenceInput reference="Community" source="community">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput reference="Event" source="event">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)

export const TalksMessageEdit: FC<any> = (props) => (
  <Edit {...props}>
    <SimpleForm toolbar={<EditToolbar />}>
      <ReferenceInput label="Sender" reference="User" source="sender">
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <ReferenceInput label="Recipient" reference="User" source="recipient">
        <AutocompleteInput optionText="username" />
      </ReferenceInput>
      <TextInput source="senderTitle" />
      <TextInput source="message" />
      <RadioButtonGroupInput
        choices={[
          { id: "Direct", name: "Direct" },
          { id: "InvitedPrivateEvent", name: "Invited To Private Event" },
          { id: "LocationDropped", name: "Location Dropped" },
          { id: "JoinedWithInviteCode", name: "Someone joined with your invite code" },
        ]}
        source="type"
      />
      <ReferenceInput reference="Community" source="community">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <ReferenceInput reference="Event" source="event">
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
    </SimpleForm>
  </Edit>
)

export const TalksMessageList: FC<any> = (props) => (
  <List
    {...props}
    filters={
      <SearchFilter {...props}>
        <TextInput label="Sender" source="sender.username" />
        <TextInput label="Recipient" source="recipient.username" />
      </SearchFilter>
    }
  >
    <Datagrid rowClick="edit">
      <ReferenceField label="Sender" reference="User" source="sender">
        <TextField source="username" />
      </ReferenceField>
      <ReferenceField label="Recipient" reference="User" source="recipient">
        <TextField source="username" />
      </ReferenceField>
      <TextField source="senderTitle" />
      <TextField source="message" />
      <TextField source="type" />
    </Datagrid>
  </List>
)

export const TalksMessageShow: FC = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <ReferenceField label="Sender" reference="User" source="senderId">
        <TextField source="username" />
      </ReferenceField>
      <ReferenceField label="Recipient" reference="User" source="recipientId">
        <TextField source="username" />
      </ReferenceField>
      <TextField source="senderTitle" />
      <TextField source="message" />
    </SimpleShowLayout>
  </Show>
)
