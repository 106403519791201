import React, { FC } from "react"
import {
  AutocompleteArrayInput,
  BooleanInput,
  ChipField,
  Create,
  Datagrid,
  Edit,
  FormDataConsumer,
  FormTab,
  List,
  NumberField,
  NumberInput,
  ReferenceArrayField,
  ReferenceArrayInput,
  SelectArrayInput,
  SelectField,
  SelectInput,
  Show,
  SimpleForm,
  SimpleShowLayout,
  SingleFieldList,
  TabbedForm,
  TextField,
  TextInput,
} from "react-admin"
import { JsonField, JsonInput } from "react-admin-json-view"
import semver from "semver"

import MediaWithUpload from "./components/MediaWithUpload"
import { Module } from "./generated/graphql"
import { EditToolbar, SearchFilter } from "./utils"

const backgroundTypeChoices = [
  { id: "Color", name: "Color" },
  { id: "Picture", name: "Picture" },
  { id: "Pictures", name: "Pictures" },
  { id: "Video", name: "Video (first module only)" },
  { id: "VideoAlways", name: "Video (always)" },
]

const currencyChoices = [
  { id: "SEK", name: "SEK" },
  { id: "DKK", name: "DKK" },
]

const countryChoices = [
  { id: "SE", name: "Sweden" },
  { id: "DK", name: "Denmark" },
]

const paymentChoices = [
  { id: "Swish", name: "Swish" },
  { id: "Stripe", name: "Stripe" },
]

const availableModules = [
  ...Object.values(Module).map((k) => ({
    id: k,
    name: k,
  })),
]

export const CommunityCreate: FC<any> = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="name" />
      <TextInput source="code" />
      <TextInput source="landingCode" />
      <NumberInput source="numPersonalizedInviteCodes" />
      <SelectInput choices={currencyChoices} source="currency" />
      <SelectInput choices={countryChoices} source="country" />
      <BooleanInput source="requireVerifiedEmailForShopAccess" />
      <TextInput source="code" />
      <TextInput source="globalInviteCode" />
      <TextInput source="globalTicketsLink" />
      <ReferenceArrayInput reference="User" source="admins">
        <AutocompleteArrayInput optionText="username" />
      </ReferenceArrayInput>
      <ReferenceArrayInput reference="User" source="members">
        <AutocompleteArrayInput optionText="username" />
      </ReferenceArrayInput>
      <JsonInput
        jsonString={false}
        reactJsonOptions={{
          collapsed: true,
          displayDataTypes: false,
          enableClipboard: false,
          name: null,
        }}
        source="styleOverride"
      />
    </SimpleForm>
  </Create>
)

export const CommunityEdit: FC<any> = (props) => {
  return (
    <Edit {...props}>
      <TabbedForm toolbar={<EditToolbar />}>
        <FormTab label="General">
          <TextField source="id" />
          <TextField source="code" />
          <TextInput source="landingCode" />
          <TextField source="landingLink" />
          <TextInput source="globalInviteCode" />

          <TextInput source="name" />
          <TextInput
            helperText="Format: version or version+codepushNUMBER (example: 3.7.0 or 3.7.0)"
            source="minAppVersion"
            validate={(val) => {
              if (val && !semver.valid(val)) {
                return "Invalid version"
              }
              return undefined
            }}
          />
          <SelectInput choices={currencyChoices} source="currency" />
          <SelectInput choices={countryChoices} source="country" />
          <MediaWithUpload
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            filename={props["id"] as string}
            label="Community Icon (app home)"
            path="community/icon"
            source={`iconUrl`}
          />
          <MediaWithUpload
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            filename={props["id"] as string}
            label="Community Small Logo (community world header/top)"
            path="community/logo"
            source={`logoSmallUrl`}
          />
          <MediaWithUpload
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            filename={props["id"] as string}
            label="Community Landing Logo (community world first module)"
            path="community/logoLanding"
            source={`logoLandingUrl`}
          />
          <MediaWithUpload
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            filename={props["id"] as string}
            label="Community Welcome Disclaimer Background"
            path="community/disclaimerBg"
            source={`logoLandingUrl`}
          />
          <NumberInput source="numPersonalizedInviteCodes" />
          <TextField label="Total members" source="members.length" />
          <ReferenceArrayInput reference="User" source="admins">
            <AutocompleteArrayInput optionText="username" />
          </ReferenceArrayInput>
          <TextInput multiline source="swishPayees" />
          <SelectArrayInput
            choices={paymentChoices}
            optionValue="id"
            source="availablePaymentMethods"
          />
          <TextInput source="stripeConnectedAccountId" />
          <JsonInput
            jsonString={false}
            reactJsonOptions={{
              collapsed: true,
              displayDataTypes: false,
              enableClipboard: false,
              name: null,
            }}
            source="paymentFees"
          />
          <SelectInput choices={backgroundTypeChoices} source="backgroundType" />
          <FormDataConsumer>
            {({ formData }) => {
              const {
                backgroundLength,
                backgroundType,
                id: communityId,
              } = formData as {
                backgroundLength: number
                backgroundType: string
                id: string
              }
              return (
                <div>
                  {backgroundType === "Color" && <TextInput source="backgroundColor" />}
                  {backgroundType === "Picture" && (
                    <MediaWithUpload
                      filename={communityId}
                      label="Picture"
                      path="community/backgroundPictures/0"
                    />
                  )}
                  {backgroundType === "Pictures" && (
                    <>
                      <NumberInput label="Number of pictures" source="backgroundLength" />
                      {Array(backgroundLength)
                        .fill(0)
                        .map((_, index) => (
                          <MediaWithUpload
                            filename={communityId}
                            label={`Picture ${index}`}
                            path="community/backgroundPictures/${index}"
                          />
                        ))}
                    </>
                  )}
                  {backgroundType === "Video" && (
                    <>
                      <MediaWithUpload
                        filename={communityId}
                        label="Placeholder"
                        path="community/backgroundVideo/placeholder"
                      />
                      <MediaWithUpload
                        filename={communityId}
                        label="Video"
                        path="community/backgroundVideo/video"
                      />
                    </>
                  )}
                  {backgroundType === "VideoAlways" && (
                    <>
                      <MediaWithUpload
                        filename={communityId}
                        label="Video"
                        path="community/backgroundVideo/video"
                      />
                    </>
                  )}
                </div>
              )
            }}
          </FormDataConsumer>
          <SelectInput allowEmpty choices={backgroundTypeChoices} source="backgroundTypeAndroid" />
          <FormDataConsumer>
            {({ formData }) => {
              const {
                backgroundLength,
                backgroundType,
                id: communityId,
              } = formData as {
                backgroundLength: number
                backgroundType: string
                id: string
              }
              return (
                <div>
                  {backgroundType === "Color" && <TextInput source="backgroundColorAndroid" />}
                  {backgroundType === "Picture" && (
                    <MediaWithUpload
                      filename={communityId}
                      label="Picture"
                      path="community/backgroundPictures/0"
                    />
                  )}
                  {backgroundType === "Pictures" && (
                    <>
                      <NumberInput label="Number of pictures" source="backgroundLengthAndroid" />
                      {Array(backgroundLength)
                        .fill(0)
                        .map((_, index) => (
                          <MediaWithUpload
                            filename={communityId}
                            label={`Picture ${index}`}
                            path="community/backgroundPictures/${index}"
                          />
                        ))}
                    </>
                  )}
                  {backgroundType === "Video" && (
                    <>
                      <MediaWithUpload
                        filename={communityId}
                        label="Placeholder"
                        path="community/backgroundVideo/placeholder"
                      />
                      <MediaWithUpload
                        filename={communityId}
                        label="Video"
                        path="community/backgroundVideo/video"
                      />
                    </>
                  )}
                  {backgroundType === "VideoAlways" && (
                    <>
                      <MediaWithUpload
                        filename={communityId}
                        label="Video"
                        path="community/backgroundVideo/video"
                      />
                    </>
                  )}
                </div>
              )
            }}
          </FormDataConsumer>
        </FormTab>
        <FormTab label="Design">
          <BooleanInput source="enableStylePolling" />
          <JsonInput
            jsonString={false}
            reactJsonOptions={{
              collapsed: true,
              displayDataTypes: false,
              enableClipboard: false,
              name: null,
            }}
            source="styleOverride"
          />
          <BooleanInput source="enableModulesV2" />
          <SelectArrayInput choices={availableModules} optionValue="id" source="availableModules" />
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export const CommunityList: FC<any> = (props) => (
  <List {...props} filters={SearchFilter(props)}>
    <Datagrid rowClick="edit">
      <TextField source="name" />
      <ReferenceArrayField label="Admins" reference="User" source="admins">
        <SingleFieldList>
          <ChipField source="username" />
        </SingleFieldList>
      </ReferenceArrayField>
      <NumberField label="Member count" source="members.length" />
      <NumberField source="numPersonalizedInviteCodes" />
      <TextField source="globalInviteCode" />
    </Datagrid>
  </List>
)

export const CommunityShow: FC = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" />
      <TextField source="name" />
      <SelectField choices={countryChoices} source="country" />
      <TextInput source="minAppVersion" />
      <NumberField source="numPersonalizedInviteCodes" />
      <TextField source="globalInviteCode" />
      <TextField source="globalTicketsLink" />
      <JsonField
        jsonString={false}
        reactJsonOptions={{
          collapsed: true,
          displayDataTypes: false,
          enableClipboard: false,
          name: null,
        }}
        source="styleOverride"
      />
    </SimpleShowLayout>
  </Show>
)
